import { takeLatest } from "redux-saga/effects";
import * as actions from "./../../actions";
import {
  deletePatternRecordSaga,
  getPatternRecordsSaga,
  getPatternsByAppointmentSaga,
  getPatternsByPatientSaga,
  submitPatternRecordSaga,
  submitPatternRecordsSaga,
} from "./patternRecords";

export function* watchPatternRecordsSaga() {
  yield takeLatest(actions.getPatternRecords, getPatternRecordsSaga);
  yield takeLatest(actions.getPatternsByAppointment, getPatternsByAppointmentSaga);
  yield takeLatest(actions.getPatternsByPatient, getPatternsByPatientSaga);
  yield takeLatest(actions.submitPatternRecord, submitPatternRecordSaga);
  yield takeLatest(actions.submitPatternRecords, submitPatternRecordsSaga);
  yield takeLatest(actions.deletePatternRecord, deletePatternRecordSaga);
}
