const API_ROUTES = {
  AUTH: {
    SIGN_IN: "auth/signin",
    REFRESH_TOKEN: "auth/refreshtoken",
    SIGN_OUT: "auth/signout",
  },
  USER: {
    GET_ALL: "admin/usermanagement/users",
    GET_BY_ID: "admin/usermanagement/get?id=",
    DELETED: "admin/usermanagement/deleted?id=",
    GET_IMAGE: "admin/usermanagement/getimage?id=:id",
    UPDATE: "admin/usermanagement/update?id=",
    UPDATE_IMAGE: "admin/usermanagement/image",
    TURN_OFF: "admin/usermanagement/turnoff?id=",
    TURN_ONN:"admin/usermanagement/turnon?id=",
    GET_ROLES: "admin/usermanagement/getrole?id=:id",
    POST_NEW_USER: "admin/usermanagement/newuser",
  },
  PATIENTS: {
    ALL: "admin/patient",
    ALL_CLINICAL_RECORDS: "admin/patient/clinical-records?id=",
    ALL_DISCHARGES: "admin/patient/discharges?id=",
    BY_ID: "admin/patient/get?id=",
    ADD_NEW: "admin/patient/addnew",
    UPDATE: "admin/patient/update?id=",
    DELETE: "admin/patient/delete?id=",
  },
  ABI: {
    ALL: "admin/abi/options",
  },
  LOCATION: {
    CITIES: "admin/city/options",
    GET_COUNTRIES: "admin/country/getall",
    GET_STATES: "admin/state/getall",
    GET_STATES_BY_COUNTRY: "admin/state/getbycountry",
    GET_CITIES_BY_STATE: "admin/city/getbystate",
  },
  TYPE_ID: {
    ALL: "admin/identityType/options",
  },
  GENDER: {
    ALL: "admin/gender/options",
  },
  APPOINTMENT: {
    ADD_NEW: "admin/appointment/addnew",
    UPDATE: "admin/appointment/update?id=",
  },
  //Clinical Records
  CONTROLLER: {
    GET_REHABILITATION: "admin/rehab/get?id=",
    GET_DISCHARGE_REASON: "admin/rehab/get/dischargesetup?id=",
  },
  MEDICAL_HISTORY: {
    ADD_NEW: "admin/medicalhistory/addnew",
    GET: "admin/medicalhistory/get?id=:id",
    GET_MEDICATION_BY_ID: "admin/medication/getbypatient?id=",
    GET_PATHOLOGY_BY_ID: "admin/pathology/getbypatient?id=",
    GET_PATTERN_EVALUATION_BY_ID: "admin/patternevaluation/getbypatient?id=",
  },
  SESSION: {
    GET_ALL: "admin/session/get_all_by?id=:id&page=:page&size=:size",
    GET_UPDATE_LEVEL: "admin/session/getupdatelevel?id=",
    GET_OBSERVATION: "admin/session/get_obs?id=",
    PUT_UPDATE_OBSERVATION: "admin/session/update_obs?id=",
    GET_SESSION_HISTORY: "admin/session/get_session_history_by?id=:id",
  },
  ALBA_SETTINGS: {
    PUT_LEVEL_ANGLE: "admin/rehab/update/levelandangle?id=",
    PUT_DISCHARGE_SETUP: "admin/rehab/update/dischargesetup?id=",
    GET_GRAPH: "api-results/alba/get/session/graph",
  },
  GEOGRAPHY: {
    GET_ALL_COUNTRIES: "admin/country/getall",
    GET_ALL_STATES: "admin/state/getall",
    GET_ALL_CITIES: "admin/city/getall",
    GET_STATE_BY_ID: "admin/state/getbycountry?id=",
    GET_CITY_BY_ID: "admin/city/getbystate?id=",
  },
  //This endpoints bring data for Select
  OPTIONS: {
    GET_DISCHARGE: "admin/discharged/options",
    GET_INCLINATION_ANGLE: "admin/inclinationangle/options",
    GET_LEVEL_ASSIGNATION: "admin/levelassignation/options",
    GET_TYPE_ID: "admin/identitytype/options",
  },
  INSTITUTION: {
    GET_ALL: "admin/institution/getall",
    GET_TYPES: "admin/institutiontype/getall",
    GET: "admin/institution/get?id=:id",
    ADD_NEW: "admin/institution/addnew",
    UPDATE: "admin/institution/update?id=:id",
    DISABLE: "admin/institution/disable?id=:id",
    ENABLE: "admin/institution/enable?id=:id",
    DELETE: "admin/institution/delete?id=:id",
    GET_OPTIONS: "admin/institution/options",
    GET_AVATAR: "admin/usermanagement/getdatainstitutionbyuser?id=:id",
  },
  IDENTITY: {
    GET_OPTIONS: "admin/identitytype/options",
    GET_ALL: "admin/identitytype/getall",
  },
  PATTERN_RECORDS: {
    GET_EVALUATIONS: "admin/patternevaluation",
    GET_BY_APPOINTMENT: "admin/patternevaluation/getbyappointment?id=:id",
    GET_BY_PATIENT: "admin/patternevaluation/getbypatient?id=:id",
    ADD_MANY: "admin/patternevaluation/addmany",
    ADD_NEW: "admin/patternevaluation/addnew",
    DELETE: "admin/patternevaluation/delete?id=:id",
  },
  PATHOLOGY: {
    GET_BY_PATIENT: "admin/pathology/getbypatient?id=:id",
    ADD_NEW: "admin/pathology/addNew",
    DELETE: "admin/pathology/deleted?id=:id",
    UPDATE: "admin/pathology/update?id=:id",
    DISABLE: "admin/pathology/disable?id=:id",
  },
  USER_TYPES: {
    GET_ALL: "admin/role/types",
    GET_BY_ID: "admin/role/get?id=",
    ADD_NEW: "admin/role/addnew",
    UPDATE: "admin/role/update?id=",
    DELETE: "admin/role/deleted?id=",
    GET_OPTIONS: "admin/role/options",
  },
  MEDICATION: {
    ADD_NEW: "admin/medication/addnew",
    UPDATE: "admin/medication/update",
    DELETE: "admin/medication/delete?id=:id",
    GET_MEDICATION_BY_ID: "admin/medication/getbypatient?id=",
    DISABLE: "admin/medication/disable?id=:id",
  },
  PDF: {
    GET: "/admin/pdf/pdf_by_patientId?id=:id"
  }
};

export default API_ROUTES;
