import { put } from "redux-saga/effects";
import * as sliceActions from "../../slices/location";
import * as locationService from "../../../services/locationService";
import { getErrorMessage } from "src/common/utils";

export function* getCountriesSaga(action) {
  try {
    yield put(sliceActions.getCountriesStart());
    const result = yield locationService.getCountries();
    const { data } = result;

    yield put(sliceActions.getCountriesSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getCountriesFail(message));
  }
}

export function* getStatesSaga(action) {
  try {
    yield put(sliceActions.getStatesStart());
    const result = yield locationService.getStatesByCountry(action.payload);
    const { data } = result;

    yield put(sliceActions.getStatesSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getStatesFail(message));
  }
}

export function* getCitiesSaga(action) {
  try {
    yield put(sliceActions.getCitiesStart());
    const result = yield locationService.getCitiesByState(action.payload);
    const { data } = result;

    yield put(sliceActions.getCitiesSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getCitiesFail(message));
  }
}