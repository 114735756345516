import { takeLatest } from "redux-saga/effects";
import * as actions from "./../../actions";
import {
  getPathologiesSaga,
  submitPathologySaga,
  deletePathologySaga,
  getMedicationsByPatientSaga,
  submitMedicationSaga,
  deleteMedicationSaga,
  disableMedicationSaga,
  disablePathologySaga
} from "./pathologies";

export function* watchPathologiesSaga() {
  yield takeLatest(actions.getPathologies, getPathologiesSaga);
  yield takeLatest(actions.submitPathology, submitPathologySaga);
  yield takeLatest(actions.deletePathology, deletePathologySaga);
  yield takeLatest(actions.getMedications, getMedicationsByPatientSaga);
  yield takeLatest(actions.submitMedication, submitMedicationSaga);
  yield takeLatest(actions.deleteMedication, deleteMedicationSaga);
  yield takeLatest(actions.disableMedication, disableMedicationSaga);
  yield takeLatest(actions.disablePathology, disablePathologySaga);
}
