import { generatePath } from "react-router-dom";
import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";

export const getPatients = () => {
  return axiosInstance.get(API_ROUTES.PATIENTS.ALL);
};

export const getPatientById = (id) => {
  return axiosInstance.get(API_ROUTES.PATIENTS.BY_ID + id);
};
