import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";
import { generatePath } from "react-router-dom";

export const getInstitutions = () => {
  return axiosInstance.get(API_ROUTES.INSTITUTION.GET_ALL);
};

export const getInstitution = (id) => {
  return axiosInstance.get(
    generatePath(API_ROUTES.INSTITUTION.GET, { id: id })
  );
};

export const getInstitutionTypes = () => {
  return axiosInstance.get(API_ROUTES.INSTITUTION.GET_TYPES); 
};

export const getOptions = () => {
  return axiosInstance.get(API_ROUTES.INSTITUTION.GET_OPTIONS);
};

export const saveInstitution = (body) => {
  return axiosInstance.post(API_ROUTES.INSTITUTION.ADD_NEW, body, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const updateInstitution = (body) => {
  return axiosInstance.put(
    generatePath(API_ROUTES.INSTITUTION.UPDATE, { id: body.idInstitution }),
    body,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
};

export const deleteInstitution = (id) => {
  return axiosInstance.post(
    generatePath(API_ROUTES.INSTITUTION.DELETE, { id: id })
  );
};

export const disableInstitution = (id) => {
  return axiosInstance.post(
    generatePath(API_ROUTES.INSTITUTION.DISABLE, { id: id })
  );
};

export const enableInstitution = (id) => {
  return axiosInstance.post(
    generatePath(API_ROUTES.INSTITUTION.ENABLE, { id: id })
  );
};

export const getInstitutionImage = (id, accessToken, tokenType) => {
  if (accessToken && tokenType) {
    axiosInstance.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
  }
  return axiosInstance.get(
    generatePath(API_ROUTES.INSTITUTION.GET_AVATAR, { id })
  );
};
