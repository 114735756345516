import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loading: false,
  patients: null,
  patient: null,
  loadingPatient: false,
};

const patients = createSlice({
  name: "patients",
  initialState: initialState,
  reducers: {
    getPatientsStart: (state, action) => updateObject(state, { loading: true, error: null }),
    getPatientsSuccess: (state, action) => updateObject(state, { loading: false, patients: action.payload }),
    getPatientsFail: (state, action) => updateObject(state, { loading: false, error: action.payload }),

    getPatientStart: (state, action) => updateObject(state, { loadingPatient: true, error: null }),
    getPatientSuccess: (state, action) => updateObject(state, { loadingPatient: false, patient: action.payload }),
    getPatientFail: (state, action) => updateObject(state, { loadingPatient: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  clearStore,
  getPatientsStart,
  getPatientsSuccess,
  getPatientsFail,
  getPatientStart,
  getPatientSuccess,
  getPatientFail,
} = patients.actions;
export default patients;
