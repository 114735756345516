import { takeLatest } from "redux-saga/effects";
import * as actions from "./../../actions";
import {
  getInstitutionsSaga,
  getOptionsSaga,
  submitInstitutionSaga,
  deleteInstitutionSaga,
  disableInstitutionSaga,
  getInstitutionTypesSaga,
  getInstitutionSaga,
  enableInstitutionSaga,
} from "./institution";

export function* watchInstitutionSaga() {
  yield takeLatest(actions.getInstitutions, getInstitutionsSaga);
  yield takeLatest(actions.getInstitution, getInstitutionSaga);
  yield takeLatest(actions.getInstituteOptions, getOptionsSaga);
  yield takeLatest(actions.submitInstitution, submitInstitutionSaga);
  yield takeLatest(actions.deleteInstitution, deleteInstitutionSaga);
  yield takeLatest(actions.disableInstitution, disableInstitutionSaga);
  yield takeLatest(actions.enableInstitution, enableInstitutionSaga);
  yield takeLatest(actions.getInstitutionTypes, getInstitutionTypesSaga);
}
 