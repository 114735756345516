import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";
import { generatePath } from "react-router-dom";

export const getPathologiesByPatient = (patientId) => {
  return axiosInstance.get(generatePath(API_ROUTES.PATHOLOGY.GET_BY_PATIENT, { id: patientId }));
};

export const addPathology = (body) => {
  return axiosInstance.post(API_ROUTES.PATHOLOGY.ADD_NEW, [body]);
};

export const updatePathology = (body) => {
  return axiosInstance.put(generatePath(API_ROUTES.PATHOLOGY.UPDATE, { id: body.idPathology }), body);
};

export const deletePathology = (id) => {
  return axiosInstance.delete(generatePath(API_ROUTES.PATHOLOGY.DELETE, { id: id }));
};

export const disablePathology = (id) => {
  return axiosInstance.post(generatePath(API_ROUTES.PATHOLOGY.DISABLE, { id: id }));
};
