import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";

export const getIdentityOptions = () => {
  return axiosInstance.get(API_ROUTES.IDENTITY.GET_OPTIONS);
};

export const getIdentities = (id) => {
  return axiosInstance.get(API_ROUTES.IDENTITY.GET_ALL);
};
