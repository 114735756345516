export const ROLES = {
  SUPER_ADMIN: 1,
  PROFESSIONAL: 3,
  ADMIN: 2,
};

const PERMISSIONS = {
  DASHBOARD: [],
  PATIENTS: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PROFESSIONAL],
  USERS: [ROLES.SUPER_ADMIN],
  USERTYPES: [ROLES.SUPER_ADMIN],
  INSTITUTIONS: [ROLES.SUPER_ADMIN],
  NEW_ADMISSION: [ROLES.PROFESSIONAL, ROLES.SUPER_ADMIN],
  CLINICAL_RECORD: [ROLES.PROFESSIONAL, ROLES.SUPER_ADMIN],
  PATTERN_RECORDS: [ROLES.PROFESSIONAL, ROLES.SUPER_ADMIN],
  PATHOLOGIES_MEDICATIONS: [ROLES.PROFESSIONAL, ROLES.SUPER_ADMIN],
};

export default PERMISSIONS;
