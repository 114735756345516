import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";
import { generatePath } from "react-router-dom";

export const getPatternRecords = () => {
  return axiosInstance.get(API_ROUTES.PATTERN_RECORDS.GET_EVALUATIONS);
};

export const getPatternRecordsByPatient = (patientId) => {
  return axiosInstance.get(generatePath(API_ROUTES.PATTERN_RECORDS.GET_BY_PATIENT, { id: patientId }));
};

export const getPatternRecordsByAppointment = (appointmentId) => {
  return axiosInstance.get(generatePath(API_ROUTES.PATTERN_RECORDS.GET_BY_APPOINTMENT, { id: appointmentId }));
};

export const addPatternRecord = (body) => {
  return axiosInstance.post(API_ROUTES.PATTERN_RECORDS.ADD_NEW, body);
};

export const addPatternRecords = (body) => {
  return axiosInstance.post(API_ROUTES.PATTERN_RECORDS.ADD_MANY, body);
};

export const deletePatternRecord = (id) => {
  return axiosInstance.post(generatePath(API_ROUTES.PATTERN_RECORDS.DELETE, { id: id }));
};
