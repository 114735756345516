import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loadingPathologies: false,
  pathologies: null,
  submitting: false,
  medications: null,
  loadingMedications: false,
  submittingMedication: false,
  submitted: false,
  medicationSubmitted: false,
  disablingMedication: false,
  disablingPathology: false,
  deleteSucces: false,
};

const pathologies = createSlice({
  name: "pathologies",
  initialState: initialState,
  reducers: {
    getPathologiesStart: (state, action) => updateObject(state, { loadingPathologies: true, error: null }),
    getPathologiesSuccess: (state, action) =>
      updateObject(state, { loadingPathologies: false, pathologies: action.payload }),
    getPathologiesFail: (state, action) => updateObject(state, { loadingPathologies: false, error: action.payload }),

    submitPathologyStart: (state, action) => updateObject(state, { submitting: true, error: null, submitted: false }),
    submitPathologySuccess: (state, action) => updateObject(state, { submitting: false, submitted: true }),
    submitPathologyFail: (state, action) => updateObject(state, { submitting: false, error: action.payload }),

    deletePathologyStart: (state, action) => updateObject(state, { deleting: true, deleteSucces: false, error: null }),
    deletePathologySuccess: (state, action) => updateObject(state, { deleting: false, deleteSucces: true }),
    deletePathologyFail: (state, action) =>
      updateObject(state, { deleting: false, deleteSucces: false, error: action.payload }),

    disablePathologyStart: (state, action) => updateObject(state, { disablingPathology: true, error: null }),
    disablePathologySuccess: (state, action) => updateObject(state, { disablingPathology: false }),
    disablePathologyFail: (state, action) => updateObject(state, { disablingPathology: false, error: action.payload }),

    getMedicationsByPatientStart: (state, action) => updateObject(state, { loadingMedications: true, error: null }),
    getMedicationsByPatientSuccess: (state, action) =>
      updateObject(state, { loadingMedications: false, medications: action.payload }),
    getMedicationsByPatientFail: (state, action) =>
      updateObject(state, { loadingMedications: false, error: action.payload }),

    submitMedicationStart: (state, action) =>
      updateObject(state, { submittingMedication: true, error: null, medicationSubmitted: false }),
    submitMedicationSuccess: (state, action) =>
      updateObject(state, { submittingMedication: false, medicationSubmitted: true }),
    submitMedicationFail: (state, action) =>
      updateObject(state, { submittingMedication: false, error: action.payload }),

    deleteMedicationStart: (state, action) =>
      updateObject(state, { deletingMedication: true, error: null, medicationDeleted: false }),
    deleteMedicationSuccess: (state, action) =>
      updateObject(state, { deletingMedication: false, medicationDeleted: true }),
    deleteMedicationFail: (state, action) => updateObject(state, { deletingMedication: false, error: action.payload }),

    disableMedicationStart: (state, action) => updateObject(state, { disablingMedication: true, error: null }),
    disableMedicationSuccess: (state, action) => updateObject(state, { disablingMedication: false }),
    disableMedicationFail: (state, action) =>
      updateObject(state, { disablingMedication: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  clearStore,
  getPathologiesStart,
  getPathologiesSuccess,
  getPathologiesFail,
  submitPathologyStart,
  submitPathologySuccess,
  submitPathologyFail,
  deletePathologyStart,
  deletePathologySuccess,
  deletePathologyFail,
  getMedicationsByPatientFail,
  getMedicationsByPatientStart,
  getMedicationsByPatientSuccess,
  submitMedicationFail,
  submitMedicationStart,
  submitMedicationSuccess,
  deleteMedicationFail,
  deleteMedicationStart,
  deleteMedicationSuccess,
  disableMedicationFail,
  disableMedicationStart,
  disableMedicationSuccess,
  disablePathologyFail,
  disablePathologyStart,
  disablePathologySuccess,
} = pathologies.actions;
export default pathologies;
