import { Routes, Route } from "react-router";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import { useSelector } from "react-redux";

const MainRoutes = ({ routes }) => {
  const { refreshToken, accessToken, tokenType } = useSelector(state => state.auth);
  const isAuthenticated = (refreshToken, accessToken, tokenType) != null ? true : false;
  const userPermissions = useSelector(state => state.auth.userRoles);

  return (
    <Routes>
      {routes.map((route, i) => {
        const privateRouteProps = {
          ...route,
          isAuth: isAuthenticated,
          userPermissions: [userPermissions],
          permissions: route.permissions,
        };
        return (
          <Route
            key={i}
            path={route.path}
            element={<RouteWithSubRoutes {...privateRouteProps} />}
          />
        );
      })}
    </Routes>
  );
};

export default MainRoutes;
