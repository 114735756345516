import moment from "moment";
import { DISPLAY_DATE_FORMAT } from "src/constants/common";

export const hasPermissions = (userPermissions: any, permissions: any[]) => {
  //check if at least one element of userPermissions array is include in permissions
  if (!userPermissions) return false;
  if (!permissions || permissions.length === 0) return true;

  return Array.isArray(userPermissions)
    ? !!userPermissions.some((up) => permissions.find((p) => up === p))
    : !!permissions.find((p) => p === userPermissions);
};

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return Object.assign({}, oldObject, updatedProperties);
};

//CALCULATES AGE FROM A YEAR OF BRITH
//return an age in string format
export const getAgeByDate = (date: string) => {
  let age: number = 0;
  let currentDate: Date = new Date();

  if (!date || date.length < 10) return age;

  let currentYear: number = currentDate.getFullYear();
  let currentMonth: number = currentDate.getMonth() - 1;
  let currentDay: number = currentDate.getDate();

  let year: number = parseInt(date.substring(0, 4));
  let month: number = parseInt(date.substring(0, 4));
  let day: number = parseInt(date.substring(0, 4));

  age = currentYear - year;
  if (currentMonth < month) age--;
  else if (currentMonth === month) {
    if (currentDay === day) age--;
  }

  return age;
};

//TO FIND OR FILTER BY PROFESSIONALS IN TABLES
// return array of objects of all professionals in table by text and value keys
export const getProfessional = (data: any) => {
  let professionals: { text: string; value: string }[] = [];
  if (!data) return professionals;

  data.forEach((e: any) => {
    professionals.push({
      text: e.professional,
      value: e.professional,
    });
  });
  return professionals;
};

//TO FIND OR FILTER BY NAME IN TABLES
// return array of objects of all names in table by text and value keys

export const getOptionsFromArray = (data: any[], propertyNames: string[]) => {
  let propertyOptions: { text: string; value: string }[] = [];
  if (!data) return [];
  data.forEach((row: any, i: number) => {
    var text: string = "";
    propertyNames.forEach(
      (prop: string) => (text = !!text ? text + " " + row[prop] : row[prop])
    );
    !propertyOptions.find((opt) => opt.text === text) &&
      propertyOptions.push({
        text: text,
        value: text,
      });
  });
  return propertyOptions;
};

//TODO delete all functions with the same code as above. Update the code where are called.
export const getNames = (data: any) => {
  let names: { text: string; value: string }[] = [];
  if (!data) return names;
  data.forEach((e: any) => {
    names.push({
      text: e.name,
      value: e.name,
    });
  });
  return names;
};

export const getUserTypes = (data: any) => {
  let usertype: { text: string; value: string }[] = [];
  if (!data) return usertype;

  data.forEach((e: any) => {
    usertype.push({
      text: e.user_types,
      value: e.user_types,
    });
  });
  return usertype;
};

export const getInstitutionType = (data: any) => {
  let institutiontype: { text: string; value: string }[] = [];
  if (!data) return institutiontype;

  data.forEach((e: any) => {
    institutiontype.push({
      text: e.institutiontype,
      value: e.institutiontype,
    });
  });
  return institutiontype;
};

export const getManager = (data: any) => {
  let manager: { text: string; value: string }[] = [];
  if (!data) return manager;

  data.forEach((e: any) => {
    manager.push({
      text: e.manager,
      value: e.manager,
    });
  });
  return manager;
};

//TO FILTER BY STATUS IN PATIENTS TABLE
// return array of objects of all status in table by text and value keys
export const getStatuses = (data: any) => {
  let statuses: { text: string; value: string }[] = [];

  if (!data) return statuses;

  data.forEach((e: any) => {
    if (e.status && e.status.props && e.status.props.children) {
      if (statuses.find((status) => status.value === e.status.props.children)) {
        return;
      }

      statuses.push({
        text: e.status.props.children.toUpperCase(),
        value: e.status.props.children,
      });
    }
  });
  return statuses;
};

/* To hide partially an email */
export const hideEmail = (email: string) => {
  if (!email || typeof email !== "string") {
    return "";
  }

  return email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, "$1***@$2");
};

/* To show only Initial letter of name and complete lastname */
export const hideName = (fullname: string) => {
  if (!fullname || typeof fullname !== "string") {
    console.error("Name is not a string");
    return "";
  }
  let lengthLastName = fullname.split(" ")[1].length;
  lengthLastName =
    lengthLastName % 2 === 0 ? lengthLastName / 2 : (lengthLastName + 1) / 2;
  const name = fullname.split(" ")[0];
  const lastname = fullname.split(" ")[1].slice(0, -lengthLastName) + "****";
  return `${name.charAt(0).toUpperCase()}. ${lastname}`;
};

/* To hide last 4 numbers of phone number */
export const hidePhone = (phone: string) => {
  if (!phone || typeof phone !== "string") {
    console.error("Phone is not a string");
    return "";
  }
  let cutLast4 = phone.slice(0, -4);
  return `${cutLast4}****`;
};
//To format dates like this in all the application
export const formatDate = (date: string) => {
  if (!date || typeof date !== "string") {
    // console.error("Date is not a string");
    return "";
  }
  return moment(date).utc().format(DISPLAY_DATE_FORMAT);
};

//To calculate the age of a patient according to his birthdate
export const calculateAge = (birthdate: string) => {
  if (!birthdate || typeof birthdate !== "string") {
    console.error("Birthdate is not a string");
    return "";
  }

  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

//To get the gender by value/id
export const getGender = (gender: number) => {
  if (!gender) {
    console.error("gender id is required for this function");
    return "";
  }
  switch (gender) {
    case 1:
      return "Femenino";
    case 2:
      return "Masculino";
    case 3:
      return "Indeterminado";
    case 4:
      return "Desconocido";
    default:
      return "";
  }
};

export const getErrorMessage = (error: any) => {
  console.error(error);
  return error.response?.data
    ? error.response.data
    : error.message
    ? error.message
    : "Something went wrong. Please try again later";
};

export const dataURLtoFile = (
  url: string,
  filename: string,
  mimeType: string
) =>
  fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
