import { Fragment } from "react";
import { compose } from "redux";
import { Modal } from "antd";
import { connect, useDispatch } from "react-redux";
import useHttpErrorHandler from "src/hooks/httpErrorHandler";

import * as actions from "../../store/actions";
import { useTranslation } from "react-i18next";
import { AxiosInstance } from "axios";
import "./WithErrorHandler.scss";
import API_ROUTES from "src/constants/apiRoutes";

const withErrorHandler = (
  WrappedComponent: React.ComponentType,
  axios: AxiosInstance
) => {
  return (props: any) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [httpError, httpErrorClosedHandler] = useHttpErrorHandler(axios, () =>
      dispatch(actions.clearAllStores())
    );

    let message;
    if (typeof httpError?.data?.statusText === "string")
      message = httpError?.data.statusText;
    else if (httpError?.data && httpError.data.message) {
      message = httpError?.data?.message;
    } else {
      message = t("common:errors.somethingWentWrong");
    }

    if (httpError) {
      Modal.destroyAll();
      httpError.status === 401 &&
      httpError.config.url === API_ROUTES.AUTH.REFRESH_TOKEN
        ? Modal.info({
            title: t("common:errors.sessionExpiredTitle"),
            content: t("common:errors.sessionExpired"),
            onCancel: httpErrorClosedHandler,
          })
        : Modal.error({
            title: "Error",
            content: message,
            onCancel: httpErrorClosedHandler,
          });
    }

    return (
      <Fragment>
        <WrappedComponent {...props} />
      </Fragment>
    );
  };
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler
);
