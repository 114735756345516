import { put } from "redux-saga/effects";
import * as sliceActions from "./../../slices/auth";
import * as sliceUserActions from "./../../slices/user";
import * as sliceInstitutionActions from "./../../slices/institution";
import * as institutionService from "./../../../services/institutionService";
import * as actions from "../../actions";
import * as authService from "./../../../services/authService";
import * as userService from "./../../../services/userService";
import LOCAL_STORAGE from "src/constants/localStorage";
import { clearAllStores } from "src/store/actions";
import { getErrorMessage } from "src/common/utils";

export function* signInSaga(action) {
  try {
    yield put(sliceActions.signInStart());
    const result = yield authService.signIn(action.payload);
    const { data } = result;
    const userProfile = data.profile;

    yield put(
      actions.getUserRoles({
        tokenType: data.tokenType,
        token: data.accessToken,
        userId: data.profile.id,
        institutionId: userProfile.idInstitution
      })
    );
    const institutionData = {
      nameInstitution: "",
      avatarInstitution: undefined,
    }
    localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, data.accessToken);
    localStorage.setItem(LOCAL_STORAGE.TOKEN_TYPE, data.tokenType);
    localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, data.refreshToken);
    localStorage.setItem(LOCAL_STORAGE.USER_PROFILE, JSON.stringify(data.profile));
    const imageResponseInstitution = yield institutionService.getInstitutionImage(data.profile.id, data.accessToken, data.tokenType);
    const imageResponse = yield userService.getUserImage(data.profile.id, data.accessToken, data.tokenType);
    userProfile.avatar = `data:image/png;base64, ${imageResponse.data}`;
    institutionData.avatarInstitution = `data:image/png;base64, ${imageResponseInstitution?.data?.img}`;
    institutionData.nameInstitution = imageResponseInstitution?.data?.name;
    yield put(sliceUserActions.getUserProfileSuccess(userProfile));
    yield put(sliceInstitutionActions.getInstitutionProfileSuccess(institutionData));
    yield put(sliceActions.signInSuccess(data));
  } catch (error) {
    try {
      yield put(sliceActions.signInFail(getSignInMessageError(error)));
    } catch (error) {
      yield put(sliceActions.signInFail(getErrorMessage(error)));
    }
  }
}

export function* signOutSaga() {
  try {
    try {
      yield authService.signOut();
    } catch (error) {
      getErrorMessage(error);
    }
    yield localStorage.clear();
    yield put(clearAllStores());
  } catch (error) {
    getErrorMessage(error);
    yield put(clearAllStores());
    yield localStorage.clear();
  }
}

export function* authCheckStateSaga(action) {
  try {
    // yield put(setIsLoading(true));//TODO: Add global spinner
    yield put(sliceActions.authCheckStart());
    const accessToken = yield localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenType = yield localStorage.getItem(LOCAL_STORAGE.TOKEN_TYPE);
    const refreshToken = yield localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);
    const userProfile = yield JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_PROFILE));
    const userRole = yield localStorage.getItem(LOCAL_STORAGE.USER_ROLE);
    const institutionData = {
      nameInstitution: "",
      avatarInstitution: undefined,
    }

    if (!accessToken || !userRole || !tokenType) {
      localStorage.clear();
    } else {
      yield put(
        sliceActions.authCheckSuccess({
          accessToken,
          tokenType,
          refreshToken,
          userRole: parseInt(userRole),
          currentPath: action.payload,
        })
      );
      const imageResponse = yield userService.getUserImage(userProfile.id, accessToken, tokenType);
      userProfile.avatar = `data:image/png;base64, ${imageResponse.data}`;
      yield put(sliceUserActions.getUserProfileSuccess(userProfile));
      const imageResponseInstitution = yield institutionService.getInstitutionImage(userProfile.id, accessToken, tokenType);
      institutionData.avatarInstitution = `data:image/png;base64, ${imageResponseInstitution?.data?.img}`;
      institutionData.nameInstitution = imageResponseInstitution?.data?.name;
      yield put(sliceInstitutionActions.getInstitutionProfileSuccess(institutionData));
    }

    // yield put(setIsLoading(false));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.authCheckFail(message));
  }
}

export function* getUserRolesSaga(action) {
  try {
    yield put(sliceActions.getUserRolesStart());
    const { payload } = action;
    const { data } = yield authService.getUserRoles(payload);
    localStorage.setItem(LOCAL_STORAGE.USER_ROLE, data.idFunctionality);
    yield put(sliceActions.getUserRolesSuccess(data.idFunctionality));
  } catch (error) {
    yield put(sliceActions.getUserRolesFail(getErrorMessage(error)));
  }
}

const getSignInMessageError = (error) => {
  if (error?.response?.data) {
    const { status, statusText } = error.response.data;
    switch (status) {
      case 401:
        return "Incorrect user or password";

      default:
        return statusText;
    }
  } else {
    throw error;
  }
};

export function* clearStoreSaga(action) {
  yield localStorage.clear();
}
