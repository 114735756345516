import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";
import { generatePath } from "react-router-dom";

export const getCountries = () => {
  return axiosInstance.get(API_ROUTES.LOCATION.GET_COUNTRIES);
};

export const getStatesByCountry = (country) => {
  return axiosInstance.get(`${API_ROUTES.LOCATION.GET_STATES_BY_COUNTRY}?id=${country}`);
};

export const getCitiesByState = (state) => {
  return axiosInstance.get(`${API_ROUTES.LOCATION.GET_CITIES_BY_STATE}?id=${state}`);
};
