import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";

// Get the language from browser user settings
const userLang = navigator.language || navigator.userLanguage;
// In case userLang is "es-ES" we need to change it to "es"
const lang = userLang.substring(0, 2);

if (!i18n.isInitialized) {
  i18n.use(initReactI18next).init({
    lng: lang,
    resources,
    fallbackLng: "es",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });
}

export default i18n;
