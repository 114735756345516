import axiosInstance from "../api/axiosInstance";
import API_ROUTES from "src/constants/apiRoutes";
import LOCAL_STORAGE from "src/constants/localStorage";
import { generatePath } from "react-router-dom";

export const signIn = (body) => {
  return axiosInstance.post(API_ROUTES.AUTH.SIGN_IN, body);
};

export const signOut = () => {
  let accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
  let refreshToken = localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);

  //This needs to use the default axios instance not the one we create
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  return axiosInstance.post(`${API_ROUTES.AUTH.SIGN_OUT}`, { refreshToken });
};

export const getUserRoles = ({ token, tokenType, userId, institutionId }) => {
  axiosInstance.defaults.headers.common["Authorization"] = `${tokenType} ${token}`;
  return axiosInstance.post(
    generatePath(API_ROUTES.USER.GET_ROLES, { id: userId }),
    { institution: institutionId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const refreshToken = (refreshToken) => {
  return axiosInstance.post(API_ROUTES.AUTH.REFRESH_TOKEN, { refreshToken: refreshToken });
};
