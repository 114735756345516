import auth from "./slices/auth";
import saga from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { watchAuthSaga } from "./sagas/auth";
import { watchInstitutionSaga } from "./sagas/institution";
import { watchIdentitySaga } from "./sagas/identity";
import { watchLocationSaga } from "./sagas/location";
import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import institution from "./slices/institution";
import identity from "./slices/identity";
import location from "./slices/location";
import patternRecords from "./slices/patternRecords";
import { watchPathologiesSaga } from "./sagas/pathologies";
import { watchPatternRecordsSaga } from "./sagas/patternRecords";
import pathologies from "./slices/pathologies";
import { watchPatientsSaga } from "./sagas/patient";
import patients from "./slices/patients";
import user from "./slices/user";
import { watchUserSaga } from "./sagas/user";

function* rootSaga() {
  yield all([fork(watchAuthSaga)]);
  yield all([fork(watchInstitutionSaga)]);
  yield all([fork(watchIdentitySaga)]);
  yield all([fork(watchLocationSaga)]);
  yield all([fork(watchPathologiesSaga)]);
  yield all([fork(watchPatternRecordsSaga)]);
  yield all([fork(watchPatientsSaga)]);
  yield all([fork(watchUserSaga)]);
}

const sagaMiddleware = saga();

const rootReducers = combineReducers({
  auth: auth.reducer,
  institution: institution.reducer,
  identity: identity.reducer,
  location: location.reducer,
  patternRecords: patternRecords.reducer,
  pathologies: pathologies.reducer,
  patients: patients.reducer,
  user: user.reducer,
});

const store = configureStore({
  reducer: rootReducers,
  middleware: [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware],
});
sagaMiddleware.run(rootSaga);

export default store;
