import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loadingRecords: false,
  patternRecords: null,
  loadingAppointmentRecords: false,
  appointmentRecords: null,
  loadingPatientRecords: false,
  patientRecords: null,
  patternDeleted: false,
};

const patternRecords = createSlice({
  name: "patternRecords",
  initialState: initialState,
  reducers: {
    getPatternRecordsStart: (state, action) => updateObject(state, { loadingRecords: true, error: null }),
    getPatternRecordsSuccess: (state, action) =>
      updateObject(state, { loadingRecords: false, patternRecords: action.payload }),
    getPatternRecordsFail: (state, action) => updateObject(state, { loadingRecords: false, error: action.payload }),

    getPatternsByAppointmentStart: (state, action) =>
      updateObject(state, { loadingAppointmentRecords: true, error: null }),
    getPatternsByAppointmentSuccess: (state, action) =>
      updateObject(state, { loadingAppointmentRecords: false, appointmentRecords: action.payload }),
    getPatternsByAppointmentFail: (state, action) =>
      updateObject(state, { loadingAppointmentRecords: false, error: action.payload }),

    getPatternsByPatientStart: (state, action) => updateObject(state, { loadingPatientRecords: true, error: null }),
    getPatternsByPatientSuccess: (state, action) =>
      updateObject(state, { loadingPatientRecords: false, patientRecords: action.payload }),
    getPatternsByPatientFail: (state, action) =>
      updateObject(state, { loadingPatientRecords: false, error: action.payload }),

    submitPatternRecordStart: (state, action) =>
      updateObject(state, { submitting: true, error: null, submitted: false }),
    submitPatternRecordSuccess: (state, action) =>
      updateObject(state, { submitting: false, patternRecord: action.payload, submitted: true }),
    submitPatternRecordFail: (state, action) =>
      updateObject(state, { submitting: false, error: action.payload, submitted: false }),

    submitPatternRecordsStart: (state, action) => updateObject(state, { submitting: true, error: null }),
    submitPatternRecordsSuccess: (state, action) =>
      updateObject(state, { submitting: false, patternRecords: action.payload }),
    submitPatternRecordsFail: (state, action) => updateObject(state, { submitting: false, error: action.payload }),

    deletePatternRecordStart: (state, action) =>
      updateObject(state, { deleting: true, error: null, patternDeleted: false }),
    deletePatternRecordSuccess: (state, action) => updateObject(state, { deleting: false, patternDeleted: true }),
    deletePatternRecordFail: (state, action) => updateObject(state, { deleting: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  clearStore,
  getPatternRecordsStart,
  getPatternRecordsSuccess,
  getPatternRecordsFail,
  getPatternsByAppointmentStart,
  getPatternsByAppointmentSuccess,
  getPatternsByAppointmentFail,
  getPatternsByPatientStart,
  getPatternsByPatientSuccess,
  getPatternsByPatientFail,
  submitPatternRecordStart,
  submitPatternRecordSuccess,
  submitPatternRecordFail,
  submitPatternRecordsStart,
  submitPatternRecordsSuccess,
  submitPatternRecordsFail,
  deletePatternRecordStart,
  deletePatternRecordSuccess,
  deletePatternRecordFail,
} = patternRecords.actions;
export default patternRecords;
