import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Spinner.scss";

const Spinner = (props: SpinProps) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className={props.className ? props.className + " spinner" : "spinner"}>
      <div className="spinner-container">
        <Spin indicator={antIcon} {...props} />
      </div>
    </div>
  );
};

export default Spinner;
