import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import API_ROUTES from "src/constants/apiRoutes";
import * as authService from "../services/authService";
import { refreshTokenSuccess } from "src/store/slices/auth";

const useHttpErrorHandler = (httpClient: AxiosInstance, onClearSession: Function) => {
  const [httpError, setHttpError] = useState<any>(null);
  const dispatch = useDispatch();

  let refreshTokenPromise: Promise<any>;
  localStorage.setItem("refreshing", "false");

  //TODO - buscar tipo de request de axios
  const reqInterceptor = httpClient.interceptors.request.use((req: any) => {
    if (!!localStorage.getItem("tokenType") && !!localStorage.getItem("accessToken")) {
      req.headers.Authorization = `${localStorage.getItem("tokenType")} ${localStorage.getItem("accessToken")}`;
    }
    setHttpError(null);
    return req;
  });

  const resInterceptor = httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        error.config.url !== API_ROUTES.AUTH.REFRESH_TOKEN &&
        error.config.url !== API_ROUTES.AUTH.SIGN_IN
      ) {
        try {
          const response = await callRefreshToken();
          httpClient.defaults.headers.common.Authorization = `${response.data.tokenType} ${response.data.accessToken}`;
          httpClient.defaults.headers.common[
            "Authorization"
          ] = `${response.data.tokenType} ${response.data.accessToken}`;
          originalRequest.headers.Authorization = `${response.data.tokenType} ${response.data.accessToken}`;
          localStorage.setItem("tokenType", response.data.tokenType);
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshing", "false");
          return httpClient(originalRequest);
        } catch (error: any) {
          onClearSession();
          setHttpError(error.response ? error.response : error);
          throw error;
        }
      } else {
        if (error.config.url !== API_ROUTES.AUTH.SIGN_IN) setHttpError(error.response ? error.response : error);
        // If the error is due to other reasons, we just throw it back to axios
        throw error;
      }
    }
  );

  const callRefreshToken = () => {
    if (localStorage.getItem("refreshing") === "false") {
      localStorage.setItem("refreshing", "true");

      refreshTokenPromise = new Promise(async (resolve, reject) => {
        await authService
          .refreshToken(localStorage.getItem("refreshToken"))
          .then(function (response) {
            const { data } = response;
            dispatch(refreshTokenSuccess(data));
            resolve(response);
          })
          .catch(function (error: any) {
            localStorage.setItem("refreshing", "false");
            reject(error);
          });
      });
    }
    return refreshTokenPromise;
  };

  useEffect(() => {
    return () => {
      httpClient.interceptors.request.eject(reqInterceptor);
      httpClient.interceptors.response.eject(resInterceptor);
    };
  }, [reqInterceptor, resInterceptor]);

  const httpErrorClosedHandler = () => {
    setHttpError(null);
  };

  return [httpError, httpErrorClosedHandler];
};

export default useHttpErrorHandler;
