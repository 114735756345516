import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loadingUser: false,
  users: null,
  user: null,
  loadingUsers: false,
  userProfile: null,
  loadingUserProfile: false,
};

const user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    getUserStart: (state, action) => updateObject(state, { loadingUser: true, error: null, user: null }),
    getUserSuccess: (state, action) => updateObject(state, { loadingUser: false, user: action.payload }),
    getUserFail: (state, action) => updateObject(state, { loadingUser: false, error: action.payload }),

    getUsersStart: (state, action) => updateObject(state, { loadingUsers: true, error: null, users: null }),
    getUsersSuccess: (state, action) => updateObject(state, { loadingUsers: false, users: action.payload }),
    getUsersFail: (state, action) => updateObject(state, { loadingUsers: false, error: action.payload }),

    getUserProfileStart: (state, action) => updateObject(state, { loadingUserProfile: true, error: null }),
    getUserProfileSuccess: (state, action) =>
      updateObject(state, { loadingUserProfile: false, userProfile: action.payload }),
    getUserProfileFail: (state, action) => updateObject(state, { loadingUserProfile: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  clearStore,
  getUserStart,
  getUserSuccess,
  getUserFail,
  getUsersStart,
  getUsersSuccess,
  getUsersFail,
  getUserProfileStart,
  getUserProfileSuccess,
  getUserProfileFail,
} = user.actions;
export default user;
