import { lazy } from "react";
import PERMISSIONS from "src/constants/permissions";
import { ROUTE_PATHS } from "src/constants/routePaths";
const Patterns = lazy(() => import("../containers/Patterns/Patterns"));
const PatternsData = lazy(() => import("../containers/Patterns/PatternsRecordsData/PatternRecordsData"));
const SignIn = lazy(() => import("../containers/SignIn/SignIn"));
const Dashboard = lazy(() => import("../containers/Dashboard/Dashboard"));
const Patients = lazy(() => import("../containers/Patients/Patients"));
const UserTypes = lazy(() => import("../containers/UserTypes/UserTypes"));
const Users = lazy(() => import("../containers/Users/Users"));
const Institutions = lazy(() => import("../containers/Institutions/Institutions"));
const NewAdmission = lazy(() => import("../containers/NewAdmission/NewAdmission"));
const NewAdmissionTracking = lazy(() => import("../containers/NewAdmissionTracking"));
const ClinicalRecords = lazy(() => import("../containers/ClinicalRecords/ClinicalRecords"));
const PathologiesMedications = lazy(() => import("../containers/PathologiesMedication/PathologiesMedications"));
const PathologiesMedicationsData = lazy(() =>
  import("../containers/PathologiesMedication/PathologiesMedicationData/PathologiesMedicationData")
);
const RehabilitationClinicalRecords = lazy(() => import("../containers/ClinicalRecords/RehabilitationClinicalRecords"));
const PageNotfound = lazy(() => import("../containers/PageNotFound/PageNotFound"));

const routeArray = [
  {
    exact: true,
    path: "/",
    component: SignIn,
  },
  {
    path: ROUTE_PATHS.SIGN_IN,
    component: SignIn,
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    private: true,
    component: Dashboard,
    permissions: PERMISSIONS.DASHBOARD,
    nameKey: "dashboard",
  },
  {
    path: ROUTE_PATHS.USERS,
    private: true,
    component: Users,
    permissions: PERMISSIONS.USERS,
    nameKey: "users",
  },
  {
    path: ROUTE_PATHS.USERTYPES,
    private: true,
    component: UserTypes,
    permissions: PERMISSIONS.USERTYPES,
    nameKey: "user-types",
  },
  {
    path: ROUTE_PATHS.INSTITUTIONS,
    private: true,
    component: Institutions,
    permissions: PERMISSIONS.INSTITUTIONS,
    nameKey: "institutions",
  },

  {
    path: ROUTE_PATHS.NEW_ADMISSION,
    private: true,
    component: NewAdmission,
    permissions: PERMISSIONS.NEW_ADMISSION,
    nameKey: "new-admission",
  },
  {
    path: ROUTE_PATHS.NEW_ADMISSION_TRACKING,
    private: true,
    component: NewAdmissionTracking,
    permissions: PERMISSIONS.NEW_ADMISSION,
    nameKey: "new-admission-tracking",
  },
  {
    path: ROUTE_PATHS.CLINICAL_RECORD,
    private: true,
    component: ClinicalRecords,
    permissions: PERMISSIONS.CLINICAL_RECORD,
    nameKey: "clinical-records",
  },
  {
    path: ROUTE_PATHS.PATTERN_RECORDS,
    private: true,
    component: Patterns,
    permissions: PERMISSIONS.PATTERN_RECORDS,
    nameKey: "pattern-records",
  },
  {
    path: ROUTE_PATHS.PATTERN_RECORDS_DATA,
    private: true,
    component: PatternsData,
    permissions: PERMISSIONS.PATTERN_RECORDS,
    nameKey: "pattern-records-data",
  },
  {
    path: ROUTE_PATHS.REHABILITATION_CLINICAL_RECORDS,
    private: true,
    component: RehabilitationClinicalRecords,
    permissions: PERMISSIONS.CLINICAL_RECORD,
    nameKey: "rehabilitation-clinical-records",
  },
  {
    path: ROUTE_PATHS.PATIENTS,
    private: true,
    component: Patients,
    permissions: PERMISSIONS.PATIENTS,
    nameKey: "patients",
  },
  {
    path: ROUTE_PATHS.PATHOLOGIES_MEDICATIONS,
    private: true,
    component: PathologiesMedications,
    permissions: PERMISSIONS.PATHOLOGIES_MEDICATIONS,
    nameKey: "pathologies-medications",
  },
  {
    path: ROUTE_PATHS.PATHOLOGIES_MEDICATIONS_DATA,
    private: true,
    component: PathologiesMedicationsData,
    permissions: PERMISSIONS.PATHOLOGIES_MEDICATIONS,
    nameKey: "pathologies-medications-data",
  },
  {
    path: "*",
    component: PageNotfound,
  },
];

export default routeArray;
