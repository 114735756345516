import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  headers: { "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL, "Content-Type": "application/json" },
  json: true,
});

export default instance;
