import { put } from "redux-saga/effects";
import * as sliceActions from "../../slices/patternRecords";
import * as actions from "../../actions";
import * as patternRecordService from "../../../services/patternRecordService";
import { getErrorMessage } from "src/common/utils";

export function* getPatternRecordsSaga(action) {
  try {
    yield put(sliceActions.getPatternRecordsStart());
    const result = yield patternRecordService.getPatternRecords();
    const { data } = result;

    yield put(sliceActions.getPatternRecordsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getPatternRecordsFail(message));
  }
}

export function* getPatternsByAppointmentSaga(action) {
  try {
    yield put(sliceActions.getPatternsByAppointmentStart());
    const result = yield patternRecordService.getPatternRecordsByAppointment(action.payload);
    const { data } = result;

    yield put(sliceActions.getPatternsByAppointmentSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getPatternsByAppointmentFail(message));
  }
}

export function* getPatternsByPatientSaga(action) {
  try {
    yield put(sliceActions.getPatternsByPatientStart());
    const result = yield patternRecordService.getPatternRecordsByPatient(action.payload);
    const { data } = result;

    yield put(sliceActions.getPatternsByPatientSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getPatternsByPatientFail(message));
  }
}

export function* submitPatternRecordSaga(action) {
  try {
    yield put(sliceActions.submitPatternRecordStart());
    const result = yield patternRecordService.addPatternRecord(action.payload);
    const { data } = result;
    yield put(actions.getPatternsByPatient(action.payload.idPatient));
    yield put(sliceActions.submitPatternRecordSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.submitPatternRecordFail(message));
  }
}

export function* submitPatternRecordsSaga(action) {
  try {
    yield put(sliceActions.submitPatternRecordsStart());
    const result = yield patternRecordService.addPatternRecords(action.payload);
    const { data } = result;

    yield put(sliceActions.submitPatternRecordsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.submitPatternRecordsFail(message));
  }
}

export function* deletePatternRecordSaga(action) {
  try {
    yield put(sliceActions.deletePatternRecordStart());
    const result = yield patternRecordService.deletePatternRecord(action.payload.id);
    const { data } = result;
    yield put(actions.getPatternsByPatient(action.payload.patientId));
    yield put(sliceActions.deletePatternRecordSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.deletePatternRecordFail(message));
  }
}
