import { takeLatest } from "redux-saga/effects";
import * as actions from "./../../actions";
import { signInSaga, signOutSaga, authCheckStateSaga, getUserRolesSaga, clearStoreSaga } from "./auth";

export function* watchAuthSaga() {
  yield takeLatest(actions.signIn, signInSaga);
  yield takeLatest(actions.signOut, signOutSaga);
  yield takeLatest(actions.tryAutoSignUp, authCheckStateSaga);
  yield takeLatest(actions.getUserRoles, getUserRolesSaga);
  yield takeLatest(actions.clearAllStores, clearStoreSaga);
}
