import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useShowLayoutElements = () => {
  const { pathname } = useLocation();

  const [inAuthLayout, setInAuthLayout] = useState<true | false>(false);

  // If we are in the some nested route of /auth we will hide the layout elements
  useEffect(() => {
    if (pathname.includes("/auth") || pathname === "/") {
      setInAuthLayout(true);
    } else {
      setInAuthLayout(false);
    }
  }, [pathname]);

  return [inAuthLayout, setInAuthLayout] as const;
};

export default useShowLayoutElements;
