import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";
import { generatePath } from "react-router-dom";

export const getMedicationsByPatient = (patientId) => {
  return axiosInstance.get(API_ROUTES.MEDICATION.GET_MEDICATION_BY_ID + patientId);
};

export const addMedication = (body) => {
  return axiosInstance.post(API_ROUTES.MEDICATION.ADD_NEW, body);
};

export const updateMedication = (body) => {
  return axiosInstance.post(API_ROUTES.MEDICATION.UPDATE, body);
};

export const deleteMedication = (id) => {
  return axiosInstance.post(generatePath(API_ROUTES.MEDICATION.DELETE, { id: id }));
};

export const disableMedication = (id) => {
  return axiosInstance.post(generatePath(API_ROUTES.MEDICATION.DISABLE, { id: id }));
};
