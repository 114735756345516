import useInAuthLayout from "src/hooks/useInAuthLayout";
import Header from "./Header/Header";
import Aside from "./Aside/Aside";
import "./index.scss";
import { Suspense } from "react";
import Spinner from "src/components/UI/Spinner/Spinner";

const Layout = ({ children }) => {
  const [inAuthLayout] = useInAuthLayout();
  return (
    <div className="layout">
      <Aside show={!inAuthLayout} className="aside" />

      <div className="content">
        <div className="content__right">
          <Header show={!inAuthLayout} className="header" />
          <main className="layout__main">
            <Suspense fallback={<Spinner spinning={true} size="default" />}>
              {children}
            </Suspense>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;







