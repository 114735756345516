import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loading: false,
  institutions: null,
  userInstitution: null,
  loadingOptions: false,
  options: null,
  submitting: false,
  institution: null,
  loadingInstitution: false,
  submitted: false,
  institutionTypes: null,
  loadingTypes: false,
  deleteSuccess: false,
};

const institution = createSlice({
  name: "institution",
  initialState: initialState,
  reducers: {
    getInstitutionsStart: (state, action) =>
      updateObject(state, { loading: true, error: null }),
    getInstitutionsSuccess: (state, action) =>
      updateObject(state, { loading: false, institutions: action.payload }),
    getInstitutionsFail: (state, action) =>
      updateObject(state, { loading: false, error: action.payload }),

    getInstitutionStart: (state, action) =>
      updateObject(state, { loadingInstitution: true, error: null }),
    getInstitutionSuccess: (state, action) =>
      updateObject(state, {
        loadingInstitution: false,
        institution: action.payload,
      }),
    getInstitutionFail: (state, action) =>
      updateObject(state, { loadingInstitution: false, error: action.payload }),

    getInstitutionProfileStart: (state, action) =>
      updateObject(state, { loading: true, error: null }),
    getInstitutionProfileSuccess: (state, action) =>
      updateObject(state, { loading: false, userInstitution: action.payload }),
    getInstitutionrProfileFail: (state, action) =>
      updateObject(state, { loading: false, error: action.payload }),

    getOptionsStart: (state, action) =>
      updateObject(state, { loadingOptions: true, error: null }),
    getOptionsSuccess: (state, action) =>
      updateObject(state, { loadingOptions: false, options: action.payload }),
    getOptionsFail: (state, action) =>
      updateObject(state, { loadingOptions: false, error: action.payload }),

    submitInstitutionStart: (state, action) =>
      updateObject(state, { submitting: true, error: null, submitted: false }),
    submitInstitutionSuccess: (state, action) =>
      updateObject(state, {
        submitting: false,
        institution: action.payload,
        submitted: true,
      }),
    submitInstitutionFail: (state, action) =>
      updateObject(state, {
        submitting: false,
        error: action.payload,
        submitted: false,
      }),

    deleteInstitutionStart: (state, action) =>
      updateObject(state, { deleting: true, error: null, deleteSucces: false }),
    deleteInstitutionSuccess: (state, action) =>
      updateObject(state, { deleting: false, deleteSuccess: true }),
    deleteInstitutionFail: (state, action) =>
      updateObject(state, { deleting: false, error: action.payload }),

    disableInstitutionStart: (state, action) =>
      updateObject(state, { disabling: true, error: null }),
    disableInstitutionSuccess: (state, action) =>
      updateObject(state, { disabling: false }),
    disableInstitutionFail: (state, action) =>
      updateObject(state, { disabling: false, error: action.payload }),

    enableInstitutionStart: (state, action) =>
      updateObject(state, { enabling: true, error: null }),
    enableInstitutionSuccess: (state, action) =>
      updateObject(state, { enabling: false }),
    enableInstitutionFail: (state, action) =>
      updateObject(state, { enabling: false, error: action.payload }),


    getInstitutionTypesStart: (state, action) =>
      updateObject(state, { loadingTypes: true, error: null }),
    getInstitutionTypesSuccess: (state, action) =>
      updateObject(state, {
        loadingTypes: false,
        institutionTypes: action.payload,
      }),
    getInstitutionTypesFail: (state, action) =>
      updateObject(state, { loadingTypes: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  getInstitutionsStart,
  getInstitutionsSuccess,
  getInstitutionsFail,
  getInstitutionStart,
  getInstitutionSuccess,
  getInstitutionFail,
  getOptionsStart,
  getOptionsSuccess,
  getOptionsFail,
  submitInstitutionStart,
  submitInstitutionSuccess,
  submitInstitutionFail,
  deleteInstitutionStart,
  deleteInstitutionSuccess,
  deleteInstitutionFail,
  disableInstitutionStart,
  disableInstitutionSuccess,
  disableInstitutionFail,
  enableInstitutionStart,          
  enableInstitutionSuccess,        
  enableInstitutionFail, 
  getInstitutionTypesStart,
  getInstitutionTypesSuccess,
  getInstitutionTypesFail,
  getInstitutionProfileStart,
  getInstitutionProfileSuccess,
  getInstitutionrProfileFail,
  clearStore,
} = institution.actions;
export default institution;
 