import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  isLoading: false,
  refreshToken: null,
  accessToken: null,
  tokenType: null,
  error: null,
  signOutStatus: null,
  checkingAuth: false,
  userRoles: null,
  currentPath: null,
};

const auth = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    signInStart: (state, { payload }) => {
      return updateObject(state, { isLoading: true, error: null });
    },
    signInSuccess: (state, { payload }) => {
      return updateObject(state, {
        isLoading: false,
        refreshToken: payload.refreshToken,
        accessToken: payload.accessToken,
        tokenType: payload.tokenType,
        userProfile: payload.profile,
      });
    },
    signInFail: (state, { payload }) => {
      return updateObject(state, {
        isLoading: false,
        error: payload,
      });
    },
    refreshTokenSuccess: (state, { payload }) => {
      return updateObject(state, {
        refreshToken: payload.refreshToken,
        accessToken: payload.accessToken,
        tokenType: payload.tokenType,
      });
    },
    authCheckStart: (state, { payload }) => updateObject(state, { checkingAuth: true }),
    authCheckSuccess: (state, { payload }) =>
      updateObject(state, {
        accessToken: payload.accessToken,
        tokenType: payload.tokenType,
        refreshToken: payload.refreshToken,
        error: null,
        checkingAuth: false,
        userRoles: payload.userRole,
        currentPath: payload.currentPath,
      }),
    authCheckFail: (state, { payload }) => updateObject(state, { checkingAuth: false }),
    getUserRolesStart: (state, { payload }) => {
      return updateObject(state, { isLoading: true, error: null });
    },
    getUserRolesSuccess: (state, { payload }) => {
      return updateObject(state, {
        userRoles: payload,
      });
    },
    getUserRolesFail: (state, { payload }) => {
      return updateObject(state, {
        isLoading: false,
        error: payload,
      });
    },
    updateProfileImage: (state, { payload }) => {
      return updateObject(state, {
        userProfile: updateObject(state.userProfile, { avatar: payload }),
      });
    },
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFail,
  signOut,
  signOutSuccess,
  authCheckStart,
  authCheckSuccess,
  authCheckFail,
  getUserRolesStart,
  getUserRolesSuccess,
  getUserRolesFail,
  refreshTokenSuccess,
  updateProfileImage,
} = auth.actions;
export default auth;
