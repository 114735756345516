import { generatePath } from "react-router-dom";
import API_ROUTES from "src/constants/apiRoutes";
import axiosInstance from "../api/axiosInstance";

export const getUsers = () => {
  return axiosInstance.get(API_ROUTES.USER.GET_ALL);
};

export const getUserById = (id) => {
  return axiosInstance.get(API_ROUTES.USER.GET_BY_ID + id);
};

//agregar header authorization
export const getUserImage = (id, accessToken, tokenType) => {
  if (accessToken && tokenType) {
    axiosInstance.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
  }
  return axiosInstance.get(generatePath(API_ROUTES.USER.GET_IMAGE, { id }));
};

export const updateImage = (userId, file) => {
  return axiosInstance.post(
    API_ROUTES.USER.UPDATE_IMAGE,
    { id: userId, file },
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
};
