import { createAction } from "@reduxjs/toolkit";

export const getInstitutions = createAction("getInstitutions");
export const getInstitutionTypes = createAction("getInstitutionTypes");
export const getInstitution = createAction("getInstitution");
export const createInstitution = createAction("createInstitution");
export const submitInstitution = createAction("submitInstitution");
export const disableInstitution = createAction("disableInstitution");
export const enableInstitution = createAction("enableInstitution");
export const deleteInstitution = createAction("deleteInstitution");
export const getInstituteOptions = createAction("getInstituteOptions");
export const clearInstitutionStore = createAction("clearInstitutionStore");
export const clearStore = createAction("clearStore");

 