export const ROUTE_PATHS = {
  DASHBOARD: "/dashboard",
  PATIENTS: "/patients",
  USERS: "/users",
  USERTYPES: "/usertypes",
  INSTITUTIONS: "/institutions",
  NEW_ADMISSION: "/new-admission",
  NEW_ADMISSION_TRACKING: "/new-admission-tracking/:id",
  CLINICAL_RECORD: "/clinical-record",
  REHABILITATION_CLINICAL_RECORDS: "/rehabilitation-clinical-record",
  PATTERN_RECORDS: "/pattern-records",
  PATTERN_RECORDS_DATA:"/pattern-records-data/:patientId",
  PATHOLOGIES_MEDICATIONS: "/pathologies-medications",
  PATHOLOGIES_MEDICATIONS_DATA: "/pathologies-medications-data/:id",
  SIGN_IN: "/auth/signin",
  SIGN_OUT: "/auth/logout",
  RECOVER_PASSWORD: "/auth/recover-password",
  NEW_PASSWORD: "/auth/new-password",
  PAGE_NOT_FOUND: "/pagenotfound",
};
