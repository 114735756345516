import React, { useEffect, useState } from "react";
import "./Header.scss";
import umov_blancohorizontal from "../../assets/images/umov_blancohorizontal.png";
import DefaultImgUser from "../../assets/images/DefaultImgUser.svg";
import { Menu, Dropdown, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../store/actions";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import axiosInstance from "../../api/axiosInstance";
import { useTranslation } from "react-i18next";
import API_ROUTES from "src/constants/apiRoutes";

const Header = ({ show }) => {
  const dispatch = useDispatch();
  const { userInstitution } = useSelector((state) => state.institution);
  const { refreshToken, accessToken } = useSelector((state) => state.auth);
  const { userProfile } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_ROUTES.USER.GET_BY_ID}${userProfile.id}`
        );
        setUserRole(response.data.roles);
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    if (userProfile?.id) {
      fetchUserRole();
    }
  }, [userProfile]);

  const SignOut = () => {
    dispatch(actions.signOut({ accessToken, refreshToken }));
  };

  const menu = (
    <Menu>
      <Menu.Item key={"item1"}>
        <a rel="noopener noreferrer" onClick={SignOut}>
          {t("common:actions.logout")}
        </a>
      </Menu.Item>
    </Menu>
  );

  if (!show) {
    return null;
  }

  return (
    <header className="umov-header">
      <div className="umov-header__combined">
        <div className="institution-logo">
          {userInstitution ? (
            <>
              <img
                src={userInstitution?.avatarInstitution}
                alt="Institution Logo"
              />
              <hr className="vertical-line" />
              <h2>{userInstitution?.nameInstitution}</h2>
            </>
          ) : (
            <>
              <img src={umov_blancohorizontal} alt="Default Institution Logo" />
              <hr className="vertical-line" />
            </>
          )}
        </div>

        <Dropdown overlay={menu} placement="bottom" arrow>
          <div className="umov-header__user">
            {userProfile?.avatar ? (
              <img
                src={userProfile.avatar}
                className="icon_circle"
                alt="User Avatar"
              />
            ) : (
              <img
                src={DefaultImgUser}
                className="icon_circle"
                alt="Default User Avatar"
              />
            )}
            <div className="info">
              {userProfile ? (
                <>
                  <p className="name">{`${userProfile?.firstName} ${userProfile?.lastName}`}</p>
                  <p className="user-type">{userRole || "User"}</p>
                </>
              ) : (
                <Spin size="small" />
              )}
            </div>
          </div>
        </Dropdown>
      </div>
    </header>
  );
};

export default withErrorHandler(Header, axiosInstance);
