import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomLink from "src/components/CustomLink";
import PERMISSIONS, { ROLES } from "src/constants/permissions";
import { ROUTE_PATHS } from "src/constants/routePaths";
import Alba from "../../assets/images/alba-logo-blanco.svg";
import { UserFillIcon } from "src/components/Icons/UserFillIcon";
import { HospitalIcon } from "src/components/Icons/HospitalIcon";
import { UsersIcon } from "src/components/Icons/UsersIcon";
import { DoctorIcon } from "src/components/Icons/DoctorIcon";
import { NewAdmissionUser } from "src/components/Icons/NewAdmissionUser";
import { FileSignature } from "src/components/Icons/FileSignature";
import { FileMedical } from "src/components/Icons/FileMedical";
import { BriefcaseMedical } from "src/components/Icons/BriefcaseMedical";
import { hasPermissions } from "../../common/utils";
import { MenuOutlined } from "@ant-design/icons";
import "./Aside.scss";
import { useSelector } from "react-redux";

interface Props {
  show: boolean;
}

const Aside = ({ show }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hasActiveItem, setHasActiveItem] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const userPermissions = useSelector((state: any) => state.auth.userRoles);

  useEffect(() => {
    const activeItemFound = settingsItems.some((item) => isActiveRoute(item.route)) || 
                            albaItems.some((item) => isActiveRoute(item.route));
    setHasActiveItem(activeItemFound);
  }, [location.pathname]);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickMenuItem = (routePath: string) => {
    navigate(routePath);
  };

  const isActiveRoute = (route: string) => {
    const currentPath = location.pathname;
    return (
      currentPath === route ||
      currentPath.startsWith(route) ||
      (route === ROUTE_PATHS.CLINICAL_RECORD && currentPath === ROUTE_PATHS.REHABILITATION_CLINICAL_RECORDS)
    );
  };

  const settingsItems = [
    {
      name: t("common:pages.patients"),
      route: ROUTE_PATHS.PATIENTS,
      permission: PERMISSIONS.PATIENTS,
      icon: <UserFillIcon fill={isActiveRoute(ROUTE_PATHS.PATIENTS) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
    {
      name: t("common:pages.users"),
      route: ROUTE_PATHS.USERS,
      permission: PERMISSIONS.USERS,
      icon: <UsersIcon fill={isActiveRoute(ROUTE_PATHS.USERS) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
    {
      name: t("common:pages.userTypes"),
      route: ROUTE_PATHS.USERTYPES,
      permission: PERMISSIONS.USERTYPES,
      icon: <DoctorIcon fill={isActiveRoute(ROUTE_PATHS.USERTYPES) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
    {
      name: t("common:pages.institutions"),
      route: ROUTE_PATHS.INSTITUTIONS,
      permission: PERMISSIONS.INSTITUTIONS,
      icon: <HospitalIcon fill={isActiveRoute(ROUTE_PATHS.INSTITUTIONS) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
  ];

  const albaItems = [
    {
      name: t("common:pages.newAdmission"),
      route: ROUTE_PATHS.NEW_ADMISSION,
      permission: PERMISSIONS.NEW_ADMISSION,
      icon: <NewAdmissionUser fill={isActiveRoute(ROUTE_PATHS.NEW_ADMISSION) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
    {
      name: t("common:pages.clinicalRecord"),
      route: ROUTE_PATHS.CLINICAL_RECORD,
      permission: PERMISSIONS.CLINICAL_RECORD,
      icon: <FileMedical fill={isActiveRoute(ROUTE_PATHS.CLINICAL_RECORD) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
    {
      name: t("common:pages.patternRecords"),
      route: ROUTE_PATHS.PATTERN_RECORDS,
      permission: PERMISSIONS.PATTERN_RECORDS,
      icon: <FileSignature fill={isActiveRoute(ROUTE_PATHS.PATTERN_RECORDS) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
    {
      name: t("common:pages.pathologiesMedications"),
      route: ROUTE_PATHS.PATHOLOGIES_MEDICATIONS,
      permission: PERMISSIONS.PATHOLOGIES_MEDICATIONS,
      icon: <BriefcaseMedical fill={isActiveRoute(ROUTE_PATHS.PATHOLOGIES_MEDICATIONS) ? "#0089E1" : "#FFFFFF"} width="20px" height="20px" />,
    },
  ];

  if (!show) {
    return null;
  }

  return (
    <aside
      className={`umov-aside ${isHovered ? "hovered" : ""} ${!hasActiveItem ? "no-active-item" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="umov-aside__logo icon-container"
        onClick={() => handleClickMenuItem(ROUTE_PATHS.DASHBOARD)}
      >
        {isHovered ? (
          <img src={Alba} alt="Logo" />
        ) : (
          <MenuOutlined className="custom-menu-outlined" />
        )}
      </div>
      <div className="umov-aside__nav">
        {hasPermissions(userPermissions, [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.PROFESSIONAL,
        ]) && <h3>{t("common:pages.submenu")}</h3>}
        <nav>
          <ul>
            {settingsItems.map((item) => {
              return (
                hasPermissions(userPermissions, item.permission) && (
                  <li key={item.name} className={isActiveRoute(item.route) ? "active" : ""}>
                    <div
                      className={`icon-container ${
                        !isHovered ? "centered-icons" : ""
                      }`}
                    >
                      <span
                        className={`icon ${
                          item.name === "Settings"
                            ? "settings-icon"
                            : "default-icon"
                        }`}
                      >
                        {item.icon}
                      </span>
                      <CustomLink to={item.route}>{item.name}</CustomLink>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
          {hasPermissions(userPermissions, [
            ROLES.PROFESSIONAL,
            ROLES.SUPER_ADMIN,
          ]) && <h3>ALBA</h3>}
          <ul>
            {albaItems.map(
              (item) =>
                hasPermissions(userPermissions, item.permission) && (
                  <li key={item.name} className={isActiveRoute(item.route) ? "active" : ""}>
                    <div
                      className={`icon-container ${
                        !isHovered ? "centered-icons" : ""
                      }`}
                    >
                      <span className="icon">{item.icon}</span>
                      <CustomLink to={item.route}>{item.name}</CustomLink>
                    </div>
                  </li>
                )
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Aside;

