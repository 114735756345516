import { useEffect } from "react";
import "./sass/index.scss";
import Layout from "./layout";
import MainRoutes from "./routing/MainRoutes";
import routeArray from "./routing/routeArray";
import "./i18n/i18";
import { useDispatch, useSelector } from "react-redux";
import { tryAutoSignUp } from "./store/actions";
import { useLocation, useNavigate } from "react-router-dom";
import Sentry from "./sentry";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = useSelector((state) => state.auth.currentPath);

  useEffect(() => {
    dispatch(tryAutoSignUp(location.pathname));
  }, [tryAutoSignUp]);

  useEffect(() => {
    if (currentPath) navigate(currentPath);
  }, [currentPath]);

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <div className="umov-app">
        <Layout>
          <MainRoutes routes={routeArray} />
        </Layout>
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
