import { put } from "redux-saga/effects";
import * as sliceActions from "../../slices/pathologies";
import * as pathologiesService from "../../../services/pathologiesService";
import * as medicationService from "../../../services/medicationService";
import * as actions from "../../actions";
import { getErrorMessage } from "src/common/utils";

export function* getPathologiesSaga(action) {
  try {
    yield put(sliceActions.getPathologiesStart());
    const result = yield pathologiesService.getPathologiesByPatient(action.payload);
    const { data } = result;

    yield put(sliceActions.getPathologiesSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getPathologiesFail(message));
  }
}

export function* submitPathologySaga(action) {
  try {
    yield put(sliceActions.submitPathologyStart());
    let result;
    if (!action.payload.idPathology) result = yield pathologiesService.addPathology(action.payload);
    else result = yield pathologiesService.updatePathology(action.payload);
    const { data } = result;
    yield put(actions.getMedications(action.payload.idPatient));
    yield put(actions.getPathologies(action.payload.idPatient));
    yield put(sliceActions.submitPathologySuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.submitPathologyFail(message));
  }
}

export function* deletePathologySaga(action) {
  try {
    yield put(sliceActions.deletePathologyStart());
    const result = yield pathologiesService.deletePathology(action.payload.id);
    const { data } = result;
    yield put(actions.getPathologies(action.payload.patientId));
    yield put(sliceActions.deletePathologySuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.deletePathologyFail(message));
  }
}

export function* getMedicationsByPatientSaga(action) {
  try {
    yield put(sliceActions.getMedicationsByPatientStart());
    const result = yield medicationService.getMedicationsByPatient(action.payload);
    const { data } = result;

    yield put(sliceActions.getMedicationsByPatientSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getMedicationsByPatientFail(message));
  }
}

export function* submitMedicationSaga(action) {
  try {
    yield put(sliceActions.submitMedicationStart());
    let result;
    if (!action.payload.idMedication) result = yield medicationService.addMedication(action.payload);
    else result = yield medicationService.updateMedication(action.payload);
    const { data } = result;
    yield put(actions.getMedications(action.payload.idPatient));
    yield put(sliceActions.submitMedicationSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.submitMedicationFail(message));
  }
}

export function* deleteMedicationSaga(action) {
  try {
    yield put(sliceActions.deleteMedicationStart());
    const result = yield medicationService.deleteMedication(action.payload.id);
    const { data } = result;
    yield put(actions.getMedications(action.payload.patientId));
    yield put(sliceActions.deleteMedicationSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.deleteMedicationFail(message));
  }
}

export function* disableMedicationSaga(action) {
  try {
    yield put(sliceActions.disableMedicationStart());
    const result = yield medicationService.disableMedication(action.payload.id);
    const { data } = result;
    yield put(actions.getMedications(action.payload.patientId));
    yield put(sliceActions.disableMedicationSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.disableMedicationFail(message));
  }
}

export function* disablePathologySaga(action) {
  try {
    yield put(sliceActions.disablePathologyStart());
    const result = yield pathologiesService.disablePathology(action.payload.id);
    yield put(actions.getMedications(action.payload.patientId));
    const { data } = result;
    yield put(actions.getPathologies(action.payload.patientId));
    yield put(sliceActions.disablePathologySuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.disablePathologyFail(message));
  }
}