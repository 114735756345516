import PrivateRoute from "../PrivateRoute";
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.

const RouteWithSubRoutes = (route, ...rest) => {
  return route.private ? (
    <PrivateRoute
      isAuth={route.isAuth}
      userPermissions={route.userPermissions}
      permissions={route.permissions}
      component={
        <route.component
          {...rest}
          routes={route.routes}
          isView={route.isView}
          isUpdate={route.isUpdate}
        />
      } // pass the sub-routes down to keep nesting
    />
  ) : (
    <route.component {...rest} routes={route.routes} />
  ); // pass the sub-routes down to keep nesting
};

export default RouteWithSubRoutes;
