import { put } from "redux-saga/effects";
import * as sliceActions from "./../../slices/institution";
import * as actions from "../../actions";
import * as institutionService from "./../../../services/institutionService";
import { dataURLtoFile, getErrorMessage } from "src/common/utils"; 

export function* getInstitutionsSaga(action) {
  try {
    yield put(sliceActions.getInstitutionsStart());
    const result = yield institutionService.getInstitutions();
    const { data } = result;
    yield put(sliceActions.getInstitutionsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getInstitutionsFail(message));
  }
}

export function* getInstitutionSaga(action) {
  try {
    yield put(sliceActions.getInstitutionStart());
    const result = yield institutionService.getInstitution(action.payload);
    const { data } = result;
    const image = yield dataURLtoFile(
      `data:image/png;base64, ${data.logo}`,
      "avatar_institution",
      "image/png"
    );
    image.uid = "-1";
    image.status = "done";
    image.thumbUrl = URL.createObjectURL(image);
    image.url = URL.createObjectURL(image);
    data.photo = image;
    yield put(sliceActions.getInstitutionSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getInstitutionFail(message));
  }
}

export function* getInstitutionTypesSaga(action) {
  try {
    yield put(sliceActions.getInstitutionTypesStart());
    const result = yield institutionService.getInstitutionTypes();
    const { data } = result;

    yield put(sliceActions.getInstitutionTypesSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getInstitutionTypesFail(message));
  }
}

export function* getOptionsSaga(action) {
  try {
    yield put(sliceActions.getOptionsStart());
    const result = yield institutionService.getOptions();
    const { data } = result;
    yield put(sliceActions.getOptionsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getOptionsFail(message));
  }
}

export function* submitInstitutionSaga(action) {
  try {
    const institutionData = {
      nameInstitution: "",
      avatarInstitution: undefined,
    };
    yield put(sliceActions.submitInstitutionStart());

    let result;
    const { isUpdate, ...body } = action.payload;

    if (!isUpdate) result = yield institutionService.saveInstitution(body);
    else result = yield institutionService.updateInstitution(body);
    yield put(actions.getInstitutions());
    const { data } = result;
    const imageResponseInstitution =
      yield institutionService.getInstitutionImage(
        action.payload.userProfileId
      );
    institutionData.avatarInstitution = `data:image/png;base64, ${imageResponseInstitution?.data?.img}`;
    institutionData.nameInstitution = imageResponseInstitution?.data?.name;
    yield put(sliceActions.getInstitutionProfileSuccess(institutionData));
    yield put(sliceActions.submitInstitutionSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.submitInstitutionFail(message));
  }
}

export function* deleteInstitutionSaga(action) {
  try {
    yield put(sliceActions.deleteInstitutionStart());
    const result = yield institutionService.deleteInstitution(action.payload);
    yield put(actions.getInstitutions());
    const { data } = result;
    yield put(sliceActions.deleteInstitutionSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.deleteInstitutionFail(message));
  }
}

export function* disableInstitutionSaga(action) {
  try {
    yield put(sliceActions.disableInstitutionStart());
    const result = yield institutionService.disableInstitution(action.payload);
    yield put(actions.getInstitutions());
    const { data } = result;
    yield put(sliceActions.disableInstitutionSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.disableInstitutionFail(message));
  }
}

export function* enableInstitutionSaga(action) {
  try {
    yield put(sliceActions.enableInstitutionStart());
    const result = yield institutionService.enableInstitution(action.payload);
    yield put(actions.getInstitutions());
    const { data } = result;
    yield put(sliceActions.enableInstitutionSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.enableInstitutionFail(message));
  }
}

