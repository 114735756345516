import { put } from "redux-saga/effects";
import * as sliceActions from "../../slices/patients";
import * as actions from "../../actions";
import * as patientsService from "../../../services/patientService";
import { getErrorMessage } from "src/common/utils";

export function* getPatientsSaga(action) {
  try {
    yield put(sliceActions.getPatientsStart());
    const result = yield patientsService.getPatients();
    const { data } = result;

    yield put(sliceActions.getPatientsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getPatientsFail(message));
  }
}

export function* getPatientSaga(action) {
  try {
    yield put(sliceActions.getPatientStart());
    const result = yield patientsService.getPatientById(action.payload);
    const { data } = result;

    yield put(sliceActions.getPatientSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getPatientFail(message));
  }
}
