import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loading: false,
  identities: null,
  options: null,
  loadingOptions: false,
};

const identity = createSlice({
  name: "identity",
  initialState: initialState,
  reducers: {
    getIdentitiesStart: (state, action) => updateObject(state, { loading: true, error: null }),
    getIdentitiesSuccess: (state, action) => updateObject(state, { loading: false, institutions: action.payload }),
    getIdentitiesFail: (state, action) => updateObject(state, { loading: false, error: action.payload }),

    getIdentityOptionsStart: (state, action) => updateObject(state, { loadingOptions: true, error: null }),
    getIdentityOptionsSuccess: (state, action) =>
      updateObject(state, { loadingOptions: false, options: action.payload }),
    getIdentityOptionsFail: (state, action) => updateObject(state, { loadingOptions: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
  getIdentitiesStart,
  getIdentitiesSuccess,
  getIdentitiesFail,
  getIdentityOptionsStart,
  getIdentityOptionsSuccess,
  getIdentityOptionsFail,
} = identity.actions;
export default identity;
