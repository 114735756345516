import { put } from "redux-saga/effects";
import * as sliceActions from "../../slices/user";
import * as userService from "../../../services/userService";
import { dataURLtoFile, getErrorMessage } from "src/common/utils";

export function* getUsersSaga(action) {
  try {
    yield put(sliceActions.getUsersStart());
    const result = yield userService.getUsers();
    const { data } = result;

    yield put(sliceActions.getUsersSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getUsersFail(message));
  }
}

export function* getUserSaga(action) {
  try {
    yield put(sliceActions.getUserStart());
    const result = yield userService.getUserById(action.payload);

    const imageResponse = yield userService.getUserImage(action.payload);
    const { data } = result;
    const image = yield dataURLtoFile(`data:image/png;base64, ${imageResponse.data}`, "user_avatar", "image/png");
    image.uid = "-1";
    image.status = "done";
    image.thumbUrl = URL.createObjectURL(image);
    image.url =  URL.createObjectURL(image);
    data.photo = image;
    yield put(sliceActions.getUserSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getUserFail(message));
  }
}

export function* getUserProfileSaga(action) {
  try {
    yield put(sliceActions.getUserProfileStart());
    const { payload } = action;
    const result = yield userService.getUserById(payload);

    const imageResponse = yield userService.getUserImage(payload);
    const { data } = result;
    data.avatar = `data:image/png;base64, ${imageResponse.data}`;
    yield put(sliceActions.getUserProfileSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getUserProfileFail(message));
  }
}
