import en_common from "../translations/en/common.json";
import en_auth from "../translations/en/auth.json";
import en_dashboard from "../translations/en/dashboard.json";
import en_newadmission from "../translations/en/newadmission.json";
import en_clinical_records from "../translations/en/clinical_records.json";
import en_patients from "../translations/en/patients.json";
import en_institutions from "../translations/en/institutions.json";
import en_users from "../translations/en/users.json";
import en_patterns from "../translations/en/patterns.json";
import en_pathologiesmedications from "../translations/en/pathologiesmedications.json";
import en_usertypes from "../translations/en/usertypes.json";
import en_tables from "../translations/en/tables.json";
import en_forms from "../translations/en/forms.json";
import en_sessionData from "../translations/en/session_data.json";
import en_pathologies from "../translations/en/pathologies.json";
import en_notifications from "../translations/en/notifications.json";
import en_notfound from "../translations/en/notfound.json";

import es_common from "../translations/es/common.json";
import es_auth from "../translations/es/auth.json";
import es_dashboard from "../translations/es/dashboard.json";
import es_newadmission from "../translations/es/newadmission.json";
import es_clinical_records from "../translations/es/clinical_records.json";
import es_patients from "../translations/es/patients.json";
import es_institutions from "../translations/es/institutions.json";
import es_users from "../translations/es/users.json";
import es_patterns from "../translations/es/patterns.json";
import es_pathologiesmedications from "../translations/es/pathologiesmedications.json";
import es_usertypes from "../translations/es/usertypes.json";
import es_tables from "../translations/es/tables.json";
import es_forms from "../translations/es/forms.json";
import es_sessionData from "../translations/es/session_data.json";
import es_pathologies from "../translations/es/pathologies.json";
import es_notifications from "../translations/es/notifications.json";
import es_notfound from "../translations/es/notfound.json";

const resources = {
  en: {
    common: en_common,
    auth: en_auth,
    dashboard: en_dashboard,
    newadmission: en_newadmission,
    clinical_records: en_clinical_records,
    patients: en_patients,
    institutions: en_institutions,
    users: en_users,
    patterns: en_patterns,
    pathologiesmedications: en_pathologiesmedications,
    usertypes: en_usertypes,
    tables: en_tables,
    forms: en_forms,
    sessionData: en_sessionData,
    pathologies: en_pathologies,
    notifications: en_notifications,
    notfound: en_notfound,
  },
  es: {
    common: es_common,
    auth: es_auth,
    dashboard: es_dashboard,
    newadmission: es_newadmission,
    clinical_records: es_clinical_records,
    patients: es_patients,
    institutions: es_institutions,
    users: es_users,
    patterns: es_patterns,
    pathologiesmedications: es_pathologiesmedications,
    usertypes: es_usertypes,
    tables: es_tables,
    forms: es_forms,
    sessionData: es_sessionData,
    pathologies: es_pathologies,
    notifications: es_notifications,
    notfound: es_notfound,
  },
};

export default resources;
