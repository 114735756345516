import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions";

const initialState = {
  loadingCities: false,
  cities: null,
  loadingStates: false,
  states: null,
  loadingCountries: false,
  countries: null,
};

const location = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    getCountriesStart: (state, action) => updateObject(state, { loadingCountries: true, error: null }),
    getCountriesSuccess: (state, action) => updateObject(state, { loadingCountries: false, countries: action.payload }),
    getCountriesFail: (state, action) => updateObject(state, { loadingCountries: false, error: action.payload }),

    getStatesStart: (state, action) => updateObject(state, { loadingStates: true, error: null }),
    getStatesSuccess: (state, action) => updateObject(state, { loadingStates: false, states: action.payload }),
    getStatesFail: (state, action) => updateObject(state, { loadingStates: false, error: action.payload }),

    getCitiesStart: (state, action) => updateObject(state, { loadingCities: true, error: null }),
    getCitiesSuccess: (state, action) => updateObject(state, { loadingCities: false, cities: action.payload }),
    getCitiesFail: (state, action) => updateObject(state, { loadingCities: false, error: action.payload }),

    clearStore: (state, action) => updateObject(state, initialState),
  },
  extraReducers: {
    [clearAllStores]: (state) => {
      return updateObject(state, initialState);
    },
  },
});

export const {
getCitiesStart,
getCitiesSuccess,
getCitiesFail,
getCountriesStart,
getCountriesSuccess,
getCountriesFail,
getStatesStart,
getStatesSuccess,
getStatesFail
} = location.actions;
export default location;
