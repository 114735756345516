import { put } from "redux-saga/effects";
import * as sliceActions from "../../slices/identity";
import * as identityService from "../../../services/identityService";
import { getErrorMessage } from "src/common/utils";

export function* getIdentityOptionsSaga(action) {
  try {
    yield put(sliceActions.getIdentityOptionsStart());
    const result = yield identityService.getIdentityOptions();
    const { data } = result;

    yield put(sliceActions.getIdentityOptionsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getIdentityOptionsFail(message));
  }
}

export function* getIdentitiesSaga(action) {
  try {
    yield put(sliceActions.getIdentitiesStart());
    const result = yield identityService.getIdentities();
    const { data } = result;
    yield put(sliceActions.getIdentitiesSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error);
    yield put(sliceActions.getIdentitiesFail(message));
  }
}
